import React, { useState } from 'react';
import { FaSearch, FaFilter, FaUserPlus, FaMapMarkerAlt, FaBriefcase, FaLightbulb } from 'react-icons/fa';
import './NetworkPage.css';

const NetworkPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');

  const networkProfiles = [
    {
      id: 1,
      name: 'Aisha Rahman',
      role: 'UX Designer',
      location: 'Dubai, UAE',
      skills: ['UI/UX', 'Figma', 'User Research'],
      interests: ['Islamic Fintech', 'Accessibility'],
      connectionStatus: 'connected'
    },
    {
      id: 2,
      name: 'Yusuf Ali',
      role: 'Full Stack Developer',
      location: 'London, UK',
      skills: ['React', 'Node.js', 'MongoDB'],
      interests: ['Blockchain', 'Smart Contracts'],
      connectionStatus: 'pending'
    },
    {
      id: 3,
      name: 'Fatima Hassan',
      role: 'Product Manager',
      location: 'Kuala Lumpur, Malaysia',
      skills: ['Agile', 'Product Strategy', 'Data Analysis'],
      interests: ['Halal E-commerce', 'Digital Marketing'],
      connectionStatus: 'not_connected'
    },
    {
      id: 4,
      name: 'Omar Farooq',
      role: 'Blockchain Developer',
      location: 'Toronto, Canada',
      skills: ['Solidity', 'Ethereum', 'Smart Contracts'],
      interests: ['Islamic Finance', 'Decentralized Apps'],
      connectionStatus: 'connected'
    },
    {
      id: 5,
      name: 'Zainab Ahmed',
      role: 'Data Scientist',
      location: 'Jakarta, Indonesia',
      skills: ['Python', 'Machine Learning', 'Big Data'],
      interests: ['AI in Islamic Finance', 'Predictive Analytics'],
      connectionStatus: 'not_connected'
    },
    {
      id: 6,
      name: 'Ibrahim Khan',
      role: 'Startup Founder',
      location: 'Riyadh, Saudi Arabia',
      skills: ['Entrepreneurship', 'Business Strategy', 'Fundraising'],
      interests: ['Islamic Venture Capital', 'Tech Startups'],
      connectionStatus: 'connected'
    },
    {
      id: 7,
      name: 'Amina Syed',
      role: 'Marketing Specialist',
      location: 'New York, USA',
      skills: ['Digital Marketing', 'Content Strategy', 'SEO'],
      interests: ['Halal Branding', 'Social Media Marketing'],
      connectionStatus: 'pending'
    },
    {
      id: 8,
      name: 'Hassan Mahmood',
      role: 'Islamic Finance Expert',
      location: 'Doha, Qatar',
      skills: ['Shariah Compliance', 'Financial Analysis', 'Risk Management'],
      interests: ['Islamic Fintech', 'Sustainable Finance'],
      connectionStatus: 'not_connected'
    },
    {
      id: 9,
      name: 'Layla Abdel',
      role: 'UI Developer',
      location: 'Cairo, Egypt',
      skills: ['React', 'CSS', 'Responsive Design'],
      interests: ['Islamic Art in UI', 'Accessibility'],
      connectionStatus: 'connected'
    },
    {
      id: 10,
      name: 'Tariq Malik',
      role: 'Cybersecurity Specialist',
      location: 'Islamabad, Pakistan',
      skills: ['Network Security', 'Ethical Hacking', 'Cryptography'],
      interests: ['Blockchain Security', 'Data Privacy'],
      connectionStatus: 'not_connected'
    }
  ];

  const filteredProfiles = networkProfiles.filter(profile => 
    (filter === 'all' || profile.connectionStatus === filter) &&
    (profile.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     profile.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
     profile.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
     profile.skills.some(skill => skill.toLowerCase().includes(searchTerm.toLowerCase())) ||
     profile.interests.some(interest => interest.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  const handleConnect = (id) => {
    // Implement connection logic here
    console.log(`Connecting to user with id: ${id}`);
  };

  return (
    <div className="network-page">
      <h1>My Network</h1>
      <div className="network-controls">
        <div className="search-bar">
          <FaSearch />
          <input 
            type="text" 
            placeholder="Search by name, role, skills, or interests" 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-dropdown">
          <FaFilter />
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All Connections</option>
            <option value="connected">Connected</option>
            <option value="pending">Pending</option>
            <option value="not_connected">Not Connected</option>
          </select>
        </div>
      </div>
      <div className="network-grid">
        {filteredProfiles.map(profile => (
          <div key={profile.id} className="network-card">
            <div className="profile-header">
              <h3>{profile.name}</h3>
              <p className="role"><FaBriefcase /> {profile.role}</p>
              <p className="location"><FaMapMarkerAlt /> {profile.location}</p>
            </div>
            <div className="profile-body">
              <div className="skills">
                <h4>Skills</h4>
                <ul>
                  {profile.skills.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
              </div>
              <div className="interests">
                <h4>Interests</h4>
                <ul>
                  {profile.interests.map((interest, index) => (
                    <li key={index}><FaLightbulb /> {interest}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="profile-footer">
              {profile.connectionStatus === 'connected' && (
                <button className="connected-btn">Connected</button>
              )}
              {profile.connectionStatus === 'pending' && (
                <button className="pending-btn">Pending</button>
              )}
              {profile.connectionStatus === 'not_connected' && (
                <button className="connect-btn" onClick={() => handleConnect(profile.id)}>
                  <FaUserPlus /> Connect
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NetworkPage;