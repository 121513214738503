import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './AllProjectsPage.css';

const ProjectCard = ({ project }) => (
  <div className="project-card">
    <h2>{project.name}</h2>
    <p>{project.description}</p>
    <div className="project-meta">
      <span className={`status ${project.status.toLowerCase().replace(' ', '-')}`}>
        {project.status}
      </span>
      <span className="category">{project.category}</span>
    </div>
    <Link to={`/project/${project.id}`} className="view-project-button">
      View Project
    </Link>
  </div>
);

const AllProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch projects data here (using dummy data for now)
    const dummyProjects = [
      {
        id: 1,
        name: 'Project Alpha',
        description: 'A platform that combines social networking, project management, and Islamic finance principles.',
        status: 'In Progress',
        category: 'Tech',
      },
      {
        id: 2,
        name: 'Halal Investments App',
        description: 'Mobile app for finding and managing Shariah-compliant investments.',
        status: 'Planning',
        category: 'Finance',
      },
      {
        id: 3,
        name: 'Muslim Entrepreneur Network',
        description: 'Online community platform for Muslim entrepreneurs to connect and collaborate.',
        status: 'Launched',
        category: 'Community',
      },
      {
        id: 4,
        name: 'Islamic EdTech Platform',
        description: 'E-learning platform focused on Islamic studies and Arabic language.',
        status: 'In Progress',
        category: 'Education',
      },
      {
        id: 5,
        name: 'Halal Food Delivery',
        description: 'App connecting users with local halal restaurants for food delivery.',
        status: 'Launched',
        category: 'Food & Beverage',
      },
      {
        id: 6,
        name: 'Muslim Travel Companion',
        description: 'App helping Muslim travelers find prayer spaces, halal food, and Muslim-friendly accommodations.',
        status: 'Planning',
        category: 'Travel',
      },
      {
        id: 7,
        name: 'Islamic Fintech Solution',
        description: 'Blockchain-based platform for transparent and Shariah-compliant financial transactions.',
        status: 'In Progress',
        category: 'Finance',
      },
      {
        id: 8,
        name: 'Muslimah Fashion Marketplace',
        description: 'E-commerce platform for modest fashion designers and customers.',
        status: 'Launched',
        category: 'Fashion',
      },
      {
        id: 9,
        name: 'Zakat Calculator and Distribution',
        description: 'App to calculate Zakat and facilitate its distribution to eligible recipients.',
        status: 'Planning',
        category: 'Finance',
      },
      {
        id: 10,
        name: 'Islamic Children\'s Entertainment',
        description: 'Platform offering Islamic-themed games, videos, and interactive content for children.',
        status: 'In Progress',
        category: 'Entertainment',
      },
      {
        id: 11,
        name: 'Halal Cosmetics Marketplace',
        description: 'E-commerce platform for halal and vegan cosmetics and personal care products.',
        status: 'Launched',
        category: 'Beauty',
      },
      {
        id: 12,
        name: 'Muslim Mental Health App',
        description: 'App connecting Muslims with mental health professionals who understand Islamic values.',
        status: 'Planning',
        category: 'Health',
      },
      {
        id: 13,
        name: 'Islamic Art and Calligraphy Platform',
        description: 'Marketplace and community for Islamic artists and calligraphers.',
        status: 'In Progress',
        category: 'Art',
      },
      {
        id: 14,
        name: 'Halal Job Board',
        description: 'Job listing platform focusing on opportunities in Muslim-friendly work environments.',
        status: 'Launched',
        category: 'Employment',
      },
      {
        id: 15,
        name: 'Muslim Charity Transparency Platform',
        description: 'Blockchain-based solution for transparent tracking of charitable donations.',
        status: 'Planning',
        category: 'Non-profit',
      },
    ];
    setProjects(dummyProjects);
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProjects = projects.filter(project => 
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="all-projects-page">
      <h1>All Projects</h1>
      <div className="project-controls">
        <input 
          type="text" 
          placeholder="Search projects..." 
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
      <div className="projects-grid">
        {filteredProjects.map(project => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
};

export default AllProjectsPage;