import React, { useState } from 'react';
import { FaVoteYea, FaHistory, FaBlog, FaLightbulb, FaCalendarAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './OurJourneyPage.css';

const TimelineCard = ({ event }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`timeline-card ${event.type}`}>
      <div className="timeline-card-header" onClick={() => setIsExpanded(!isExpanded)}>
        <h3>{event.title}</h3>
        <div className="timeline-card-date">
          <FaCalendarAlt /> {event.date}
        </div>
        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {isExpanded && (
        <div className="timeline-card-content">
          <p>{event.description}</p>
          {event.type === 'future' && <FaLightbulb className="future-icon" />}
        </div>
      )}
    </div>
  );
};

const OurJourneyPage = () => {
  const [activeTab, setActiveTab] = useState('blog');

  const blogPosts = [
    { id: 1, title: 'The Future of Islamic Fintech', date: '2023-08-01', excerpt: 'Exploring the potential of blockchain in Islamic finance...' },
    { id: 2, title: 'Success Story: How Aisha Built Her Startup', date: '2023-07-15', excerpt: 'Learn how Aisha turned her idea into a thriving business...' },
    { id: 3, title: 'Upcoming Features: AI-Powered Matchmaking', date: '2023-08-10', excerpt: 'We\'re excited to announce our plans for implementing AI-powered matchmaking...' },
  ];

  const proposals = [
    { id: 1, title: 'Implement AI-powered matchmaking', status: 'open', votes: { yes: 120, no: 30 }, dueDate: '2023-08-30' },
    { id: 2, title: 'Launch mobile app', status: 'closed', votes: { yes: 200, no: 50 }, result: 'passed', implementationDate: '2023-12-01' },
    { id: 3, title: 'Organize annual Muslim tech conference', status: 'open', votes: { yes: 80, no: 20 }, dueDate: '2023-09-15' },
  ];

  const timelineEvents = [
    { date: '2023-05-01', title: 'Platform Launch', description: 'Our journey begins!', type: 'past' },
    { date: '2023-06-15', title: 'First Community Vote', description: 'Members voted on the new logo design.', type: 'past' },
    { date: '2023-07-30', title: 'Milestone: 1000 Members', description: 'We reached 1000 active members!', type: 'past' },
    { date: '2023-09-01', title: 'Partnership Announcement', description: 'Collaboration with leading Islamic finance institutions.', type: 'past' },
    { date: '2023-10-15', title: 'First Hackathon', description: 'Bringing together Muslim tech talents.', type: 'past' },
    { date: '2023-12-01', title: 'Mobile App Launch', description: 'Our mobile app will be available on iOS and Android.', type: 'future' },
    { date: '2024-Q1', title: 'AI-Powered Matchmaking', description: 'Implementing advanced algorithms to connect members more effectively.', type: 'future' },
    { date: '2024-Q2', title: 'Global Muslim Tech Summit', description: 'Organizing our first annual conference for Muslim tech professionals.', type: 'future' },
    { date: '2024-Q3', title: 'Expansion to New Markets', description: 'Reaching out to Muslim communities in Southeast Asia and Africa.', type: 'future' },
    { date: '2024-Q4', title: 'Launch of Incubator Program', description: 'Supporting early-stage Muslim-led startups.', type: 'future' },
  ];

  return (
    <div className="our-journey-page">
      <h1>Our Journey</h1>
      <div className="journey-tabs">
        <button className={activeTab === 'blog' ? 'active' : ''} onClick={() => setActiveTab('blog')}>
          <FaBlog /> Blog & Updates
        </button>
        <button className={activeTab === 'proposals' ? 'active' : ''} onClick={() => setActiveTab('proposals')}>
          <FaVoteYea /> Proposals & Voting
        </button>
        <button className={activeTab === 'timeline' ? 'active' : ''} onClick={() => setActiveTab('timeline')}>
          <FaHistory /> Timeline
        </button>
      </div>

      {activeTab === 'blog' && (
        <div className="blog-posts">
          {blogPosts.map(post => (
            <div key={post.id} className="blog-card">
              <h3>{post.title}</h3>
              <p className="post-date"><FaCalendarAlt /> {post.date}</p>
              <p>{post.excerpt}</p>
              <a href="#" className="read-more">Read More</a>
            </div>
          ))}
        </div>
      )}

      {activeTab === 'proposals' && (
        <div className="proposals">
          {proposals.map(proposal => (
            <div key={proposal.id} className="proposal-card">
              <h3>{proposal.title}</h3>
              <p className="proposal-status">Status: {proposal.status}</p>
              {proposal.status === 'open' && (
                <>
                  <p className="proposal-due-date"><FaCalendarAlt /> Voting ends: {proposal.dueDate}</p>
                  <div className="voting-buttons">
                    <button className="vote-yes">Vote Yes</button>
                    <button className="vote-no">Vote No</button>
                  </div>
                </>
              )}
              <div className="vote-results">
                <div className="yes-votes" style={{width: `${(proposal.votes.yes / (proposal.votes.yes + proposal.votes.no)) * 100}%`}}>
                  Yes: {proposal.votes.yes}
                </div>
                <div className="no-votes" style={{width: `${(proposal.votes.no / (proposal.votes.yes + proposal.votes.no)) * 100}%`}}>
                  No: {proposal.votes.no}
                </div>
              </div>
              {proposal.result && <p className="proposal-result">Result: {proposal.result}</p>}
              {proposal.implementationDate && <p className="implementation-date"><FaCalendarAlt /> Implementation date: {proposal.implementationDate}</p>}
            </div>
          ))}
        </div>
      )}

      {activeTab === 'timeline' && (
        <div className="timeline-container">
          <div className="timeline-section">
            <h2>Milestones Achieved</h2>
            {timelineEvents.filter(event => event.type === 'past').map((event, index) => (
              <TimelineCard key={index} event={event} />
            ))}
          </div>
          <div className="timeline-section">
            <h2>Future Plans</h2>
            {timelineEvents.filter(event => event.type === 'future').map((event, index) => (
              <TimelineCard key={index} event={event} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OurJourneyPage;