import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaSearch, FaBell, FaEnvelope, FaUserCircle, FaChartLine, FaCalendar, FaProjectDiagram, FaUsers, FaLightbulb } from 'react-icons/fa';
import './HomePage.css';

function HomePage() {
  const [activeTab, setActiveTab] = useState('feed');

  const user = {
    name: 'John Doe',
    avatar: 'https://via.placeholder.com/50',
    projectCount: 3,
    opportunityCount: 5,
  };

  const feedItems = [
    { id: 1, type: 'project_update', user: 'Sarah Ahmed', content: 'Just reached a major milestone in Project Alpha!', likes: 15, comments: 3 },
    { id: 2, type: 'new_opportunity', user: 'Oumafy Team', content: 'New opportunity: UX Designer needed for Halal Investments App', likes: 8, comments: 1 },
    { id: 3, type: 'user_post', user: 'Mohammed Ali', content: 'Looking for co-founders for a new Islamic fintech startup. Anyone interested?', likes: 22, comments: 7 },
  ];

  const upcomingEvents = [
    { id: 1, title: 'Startup Pitch Competition', date: '2023-07-15' },
    { id: 2, title: 'Islamic Finance Webinar', date: '2023-07-22' },
  ];

  const activeProjects = [
    { id: 1, name: 'Project Alpha', progress: 75 },
    { id: 2, name: 'Halal Investments App', progress: 30 },
    { id: 3, name: 'Muslim Entrepreneur Network', progress: 90 },
  ];

  const recommendedConnections = [
    { id: 1, name: 'Aisha Malik', role: 'UX Designer' },
    { id: 2, name: 'Yusuf Rahman', role: 'Investor' },
    { id: 3, name: 'Fatima Hassan', role: 'Marketing Specialist' },
  ];

  return (
    <div className="home-page">
      <header className="dashboard-header">
        <h1>Welcome back, {user.name}!</h1>
        <div className="user-actions">
          <Link to="/notifications" className="icon-button"><FaBell /></Link>
          <Link to="/messages" className="icon-button"><FaEnvelope /></Link>
          <Link to="/profile" className="user-avatar">
            <FaUserCircle />
          </Link>
        </div>
      </header>

      <main className="dashboard-content">
        <section className="dashboard-stats">
          <div className="stat-card">
            <FaProjectDiagram />
            <h3>{user.projectCount}</h3>
            <p>Active Projects</p>
          </div>
          <div className="stat-card">
            <FaLightbulb />
            <h3>{user.opportunityCount}</h3>
            <p>New Opportunities</p>
          </div>
          <div className="stat-card">
            <FaCalendar />
            <h3>{upcomingEvents.length}</h3>
            <p>Upcoming Events</p>
          </div>
          <div className="stat-card">
            <FaUsers />
            <h3>{recommendedConnections.length}</h3>
            <p>Recommended Connections</p>
          </div>
        </section>

        <div className="dashboard-main">
          <section className="content-section">
            <h2>Activity Feed</h2>
            <div className="post-box">
              <FaUserCircle className="user-avatar" />
              <input type="text" placeholder="Share an update or idea..." />
              <button className="post-button">Post</button>
            </div>
            {feedItems.map(item => (
              <div key={item.id} className="feed-item">
                <div className="feed-item-header">
                  <FaUserCircle className="user-avatar" />
                  <div>
                    <h3>{item.user}</h3>
                    <span className="timestamp">2 hours ago</span>
                  </div>
                </div>
                <p>{item.content}</p>
                <div className="feed-item-actions">
                  <button>Like ({item.likes})</button>
                  <button>Comment ({item.comments})</button>
                  <button>Share</button>
                </div>
              </div>
            ))}
          </section>

          <aside className="dashboard-sidebar">
            <section className="sidebar-section">
              <h2>Quick Actions</h2>
              <Link to="/new-project" className="action-button"><FaPlus /> Create Project</Link>
              <Link to="/opportunities" className="action-button"><FaSearch /> Find Opportunities</Link>
            </section>

            <section className="sidebar-section">
              <h2>Active Projects</h2>
              {activeProjects.map(project => (
                <div key={project.id} className="project-item">
                  <h3>{project.name}</h3>
                  <div className="progress-bar">
                    <div className="progress" style={{width: `${project.progress}%`}}></div>
                  </div>
                  <span>{project.progress}% Complete</span>
                </div>
              ))}
            </section>

            <section className="sidebar-section">
              <h2>Upcoming Events</h2>
              <ul className="event-list">
                {upcomingEvents.map(event => (
                  <li key={event.id}>
                    <h4>{event.title}</h4>
                    <p>{event.date}</p>
                  </li>
                ))}
              </ul>
            </section>

            <section className="sidebar-section">
              <h2>Recommended Connections</h2>
              <ul className="connection-list">
                {recommendedConnections.map(connection => (
                  <li key={connection.id}>
                    <FaUserCircle className="user-avatar" />
                    <div>
                      <h4>{connection.name}</h4>
                      <p>{connection.role}</p>
                    </div>
                    <button className="connect-button">Connect</button>
                  </li>
                ))}
              </ul>
            </section>
          </aside>
        </div>
      </main>
    </div>
  );
}

export default HomePage;