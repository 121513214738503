import React, { useState } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaLanguage, FaClock, FaCalendarAlt, FaBullseye, FaBook, FaTrophy, FaLinkedin, FaGithub, FaTwitter, FaPencilAlt } from 'react-icons/fa';
import './ProfilePage.css';

const ProfilePage = () => {
  const [user, setUser] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '+1 (555) 123-4567',
    location: 'New York, NY',
    bio: 'I am a Muslim entrepreneur passionate about technology and innovation.',
    skills: ['React', 'Node.js', 'Islamic Finance', 'Project Management'],
    interests: ['Halal Startups', 'Blockchain', 'Social Impact', 'Sustainable Development'],
    education: [
      { degree: 'MBA', institution: 'Harvard Business School', year: '2018' },
      { degree: 'BSc Computer Science', institution: 'MIT', year: '2014' }
    ],
    experience: [
      { title: 'Senior Developer', company: 'Tech Innovators Inc.', duration: '2018 - Present' },
      { title: 'Project Manager', company: 'Global Solutions Ltd.', duration: '2015 - 2018' }
    ],
    languages: ['English (Native)', 'Arabic (Fluent)', 'French (Intermediate)'],
    availability: 'Part-time, 20 hours per week',
    preferredProjectDuration: '3-6 months',
    goals: [
      'Launch a halal fintech startup',
      'Contribute to open-source projects in the Islamic finance sector',
      'Mentor young Muslim entrepreneurs'
    ],
    story: 'Growing up in a multicultural environment, I always dreamed of bridging the gap between technology and Islamic principles. My journey in tech started...',
    achievements: [
      'Developed a Shariah-compliant cryptocurrency trading platform',
      'Published research on blockchain applications in Islamic finance',
      'Mentored 50+ Muslim youth in STEM fields'
    ],
    socialLinks: {
      linkedin: 'https://www.linkedin.com/in/johndoe',
      github: 'https://github.com/johndoe',
      twitter: 'https://twitter.com/johndoe'
    }
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleArrayInputChange = (e, field) => {
    const { value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [field]: value.split(',').map(item => item.trim())
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Updated user:', user);
    setIsEditing(false);
  };

  return (
    <div className="profile-page">
      <div className="profile-header">
        <h1>{user.name}</h1>
        <p className="role">{user.skills[0]}</p>
        <p className="location"><FaMapMarkerAlt /> {user.location}</p>
      </div>
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          {/* Basic Information */}
          <div className="profile-section">
            <h2>Basic Information</h2>
            <div className="form-group">
              <label htmlFor="name"><FaUser /> Name:</label>
              <input type="text" id="name" name="name" value={user.name} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="email"><FaEnvelope /> Email:</label>
              <input type="email" id="email" name="email" value={user.email} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="phone"><FaPhone /> Phone:</label>
              <input type="tel" id="phone" name="phone" value={user.phone} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="location"><FaMapMarkerAlt /> Location:</label>
              <input type="text" id="location" name="location" value={user.location} onChange={handleInputChange} />
            </div>
          </div>

          {/* Bio and Skills */}
          <div className="profile-section">
            <h2>Bio and Skills</h2>
            <div className="form-group">
              <label htmlFor="bio">Bio:</label>
              <textarea id="bio" name="bio" value={user.bio} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="skills">Skills (comma-separated):</label>
              <input type="text" id="skills" name="skills" value={user.skills.join(', ')} onChange={(e) => handleArrayInputChange(e, 'skills')} />
            </div>
            <div className="form-group">
              <label htmlFor="interests">Interests (comma-separated):</label>
              <input type="text" id="interests" name="interests" value={user.interests.join(', ')} onChange={(e) => handleArrayInputChange(e, 'interests')} />
            </div>
            <div className="form-group">
              <label htmlFor="languages"><FaLanguage /> Languages (comma-separated):</label>
              <input type="text" id="languages" name="languages" value={user.languages.join(', ')} onChange={(e) => handleArrayInputChange(e, 'languages')} />
            </div>
          </div>

          {/* Availability and Goals */}
          <div className="profile-section">
            <h2>Availability and Goals</h2>
            <div className="form-group">
              <label htmlFor="availability"><FaClock /> Availability:</label>
              <input type="text" id="availability" name="availability" value={user.availability} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="preferredProjectDuration"><FaCalendarAlt /> Preferred Project Duration:</label>
              <input type="text" id="preferredProjectDuration" name="preferredProjectDuration" value={user.preferredProjectDuration} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="goals"><FaBullseye /> Goals (comma-separated):</label>
              <textarea id="goals" name="goals" value={user.goals.join(', ')} onChange={(e) => handleArrayInputChange(e, 'goals')} />
            </div>
          </div>

          {/* Story and Achievements */}
          <div className="profile-section">
            <h2>Story and Achievements</h2>
            <div className="form-group">
              <label htmlFor="story"><FaBook /> Your Story:</label>
              <textarea id="story" name="story" value={user.story} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="achievements"><FaTrophy /> Achievements (comma-separated):</label>
              <textarea id="achievements" name="achievements" value={user.achievements.join(', ')} onChange={(e) => handleArrayInputChange(e, 'achievements')} />
            </div>
          </div>

          {/* Social Links */}
          <div className="profile-section">
            <h2>Social Links</h2>
            <div className="form-group">
              <label htmlFor="linkedin"><FaLinkedin /> LinkedIn:</label>
              <input type="url" id="linkedin" name="linkedin" value={user.socialLinks.linkedin} onChange={(e) => setUser(prevUser => ({ ...prevUser, socialLinks: { ...prevUser.socialLinks, linkedin: e.target.value } }))} />
            </div>
            <div className="form-group">
              <label htmlFor="github"><FaGithub /> GitHub:</label>
              <input type="url" id="github" name="github" value={user.socialLinks.github} onChange={(e) => setUser(prevUser => ({ ...prevUser, socialLinks: { ...prevUser.socialLinks, github: e.target.value } }))} />
            </div>
            <div className="form-group">
              <label htmlFor="twitter"><FaTwitter /> Twitter:</label>
              <input type="url" id="twitter" name="twitter" value={user.socialLinks.twitter} onChange={(e) => setUser(prevUser => ({ ...prevUser, socialLinks: { ...prevUser.socialLinks, twitter: e.target.value } }))} />
            </div>
          </div>

          <button type="submit" className="save-button"><FaPencilAlt /> Save Changes</button>
        </form>
      ) : (
        <div className="profile-info">
          <div className="profile-section">
            <h2>About Me</h2>
            <p>{user.bio}</p>
          </div>

          <div className="profile-section">
            <h2>Skills & Interests</h2>
            <div className="tags-container">
              {user.skills.map((skill, index) => (
                <span key={`skill-${index}`} className="tag skill-tag">{skill}</span>
              ))}
              {user.interests.map((interest, index) => (
                <span key={`interest-${index}`} className="tag interest-tag">{interest}</span>
              ))}
            </div>
          </div>

          <div className="profile-section">
            <h2>Languages</h2>
            <div className="tags-container">
              {user.languages.map((language, index) => (
                <span key={`language-${index}`} className="tag language-tag">{language}</span>
              ))}
            </div>
          </div>

          <div className="profile-section">
            <h2>Availability</h2>
            <p><FaClock /> {user.availability}</p>
            <p><FaCalendarAlt /> Preferred Project Duration: {user.preferredProjectDuration}</p>
          </div>

          <div className="profile-section">
            <h2>Goals</h2>
            <ul className="goals-list">
              {user.goals.map((goal, index) => (
                <li key={`goal-${index}`}><FaBullseye /> {goal}</li>
              ))}
            </ul>
          </div>

          <div className="profile-section">
            <h2>My Story</h2>
            <p>{user.story}</p>
          </div>

          <div className="profile-section">
            <h2>Achievements</h2>
            <ul className="achievements-list">
              {user.achievements.map((achievement, index) => (
                <li key={`achievement-${index}`}><FaTrophy /> {achievement}</li>
              ))}
            </ul>
          </div>

          <div className="profile-section">
            <h2>Social Links</h2>
            <div className="social-links">
              <a href={user.socialLinks.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin /> LinkedIn</a>
              <a href={user.socialLinks.github} target="_blank" rel="noopener noreferrer"><FaGithub /> GitHub</a>
              <a href={user.socialLinks.twitter} target="_blank" rel="noopener noreferrer"><FaTwitter /> Twitter</a>
            </div>
          </div>

          <button onClick={() => setIsEditing(true)} className="edit-button"><FaPencilAlt /> Edit Profile</button>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;