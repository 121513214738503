import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaHome, FaBookOpen, FaProjectDiagram, FaSearch, FaUsers, FaBars, FaInfoCircle, FaBook, FaUser } from 'react-icons/fa';
import './Layout.css';

function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { path: '/', icon: FaHome, label: 'Home' },
    { path: '/our-journey', icon: FaBookOpen, label: 'Our Journey' }, // New item
    { path: '/projects', icon: FaProjectDiagram, label: 'Projects' },
    { path: '/opportunities', icon: FaSearch, label: 'Opportunities' },
    { path: '/network', icon: FaUsers, label: 'Network' },
    { path: '/profile', icon: FaUser, label: 'Profile' },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="layout">
      <aside className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-toggle" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <FaBars />
        </div>
        <nav className="side-nav">
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) => isActive ? 'active' : ''}
              onClick={() => window.innerWidth <= 768 && setIsSidebarOpen(false)}
            >
              <item.icon />
              {isSidebarOpen && <span>{item.label}</span>}
            </NavLink>
          ))}
        </nav>
        <div className="sidebar-bottom">
          <NavLink
            to="/about"
            className={({ isActive }) => isActive ? 'active' : ''}
            onClick={() => window.innerWidth <= 768 && setIsSidebarOpen(false)}
          >
            <FaInfoCircle />
            {isSidebarOpen && <span>About</span>}
          </NavLink>
          <a
            href="https://info.oumafy.org"
            target="_blank"
            rel="noopener noreferrer"
            className="knowledgebase-link"
          >
            <FaBook />
            {isSidebarOpen && <span>Knowledgebase</span>}
          </a>
        </div>
      </aside>
      <main className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        {children}
      </main>
    </div>
  );
}

export default Layout;