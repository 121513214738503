import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './components/HomePage';
import OurJourneyPage from './components/OurJourneyPage';
import OumafyOpportunityBoard from './components/OumafyOpportunityBoard';
import OpportunityDetail from './components/OpportunityDetail';
import ProjectsPage from './components/ProjectsPage';
import AllProjectsPage from './components/AllProjectsPage';
import AboutPage from './components/AboutPage';
import NetworkPage from './components/NetworkPage';
import ProfilePage from './components/ProfilePage';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/our-journey" element={<OurJourneyPage />} />
          <Route path="/opportunities" element={<OumafyOpportunityBoard />} />
          <Route path="/opportunity/:id" element={<OpportunityDetail />} />
          <Route path="/project/:id" element={<ProjectsPage />} />
          <Route path="/projects" element={<AllProjectsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/network" element={<NetworkPage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;