import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>About Oumafy</h1>
      <p className="tagline">A community where Muslims can share and develop their ideas together</p>
      
      <section>
        <h2>Our Mission</h2>
        <p>We're focused on Muslim entrepreneurs who want to solve big problems for themselves and the ummah. Our simple mission is to build an environment where entrepreneurs can help each other build halal businesses.</p>
      </section>
      
      <section>
        <h2>Our Vision</h2>
        <p>We believe in the power of unity. This community is the evolution of how Muslims can come together to create, innovate, and solve problems. We're not just building businesses; we're creating a platform that makes everything possible for the ummah.</p>
      </section>
      
      <section className="features">
        <h2>What We Offer</h2>
        <ul>
          <li>Collaborate with your team and share profits/equity ownership</li>
          <li>Choose your role and shape the future of our business together</li>
          <li>Access to a network of Muslim entrepreneurs worldwide</li>
          <li>Opportunities to participate in Shariah-compliant startups</li>
          <li>Access to halal funding opportunities</li>
        </ul>
      </section>
      
      <section>
        <h2>Join Oumafy</h2>
        <p>By joining Oumafy, you become part of a growing network of Muslim entrepreneurs, innovators, and problem-solvers. Together, we can build something greater than we could individually.</p>
        <a href="https://join.slack.com/t/oumafy/shared_invite/zt-wyqyymv9-6dbt~V27_3sFSMA9OQMung" className="cta-button">Join Our Community</a>
      </section>
    </div>
  );
};

export default AboutPage;