import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './ProjectsPage.css';
import ProjectCard from './ui/ProjectCard';

const ProjectsPage = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [activeTab, setActiveTab] = useState('updates');
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    // Fetch project data here (using dummy data for now)
    const dummyProject = {
      id: id,
      name: 'Project Alpha',
      purpose: 'To revolutionize the way Muslims connect and collaborate on business ideas.',
      idea: 'Create a platform that combines social networking, project management, and Islamic finance principles.',
      why: 'Many Muslim entrepreneurs lack the resources and network to bring their ideas to life while adhering to Islamic principles.',
      goals: [
        'Connect 1 million Muslim entrepreneurs worldwide',
        'Facilitate the launch of 10,000 Shariah-compliant startups',
        'Provide $100 million in halal funding opportunities'
      ],
      foundingTeam: [
        { name: 'Ahmed Hassan', role: 'CEO & Founder' },
        { name: 'Fatima Ali', role: 'CTO' },
        { name: 'Yusuf Rahman', role: 'CFO' }
      ],
      status: 'In development',
      opportunities: [
        { title: 'Frontend Developer', type: 'Technical' },
        { title: 'Islamic Finance Advisor', type: 'Advisory' },
        { title: 'Community Manager', type: 'Operations' }
      ],
      timeline: [
        { date: '2024 Q2', milestone: 'Beta Launch' },
        { date: '2024 Q4', milestone: 'Official Launch' },
        { date: '2025 Q2', milestone: 'Expansion to 50 countries' }
      ],
      requirements: [
        'Strong technical team',
        'Shariah compliance experts',
        'Active community engagement',
        'Seed funding of $500,000'
      ],
      founder: 'Jane Doe',
      timeline: '12 months',
      overview: 'Our project aims to develop cutting-edge renewable energy technology that will significantly reduce carbon emissions and provide affordable clean energy to communities worldwide. We\'re combining advanced solar cell technology with innovative energy storage solutions to create a sustainable and reliable power source.',
      updates: [
        { id: 1, title: 'Update 1', content: 'Successfully completed initial prototype testing.' },
        { id: 2, title: 'Update 2', content: 'Secured additional funding for phase 2 development.' },
        { id: 3, title: 'Update 3', content: 'Partnered with leading research institution for further R&D.' },
      ],
      milestones: [
        { id: 1, title: 'Prototype Development', date: '2023 Q3' },
        { id: 2, title: 'Field Testing', date: '2024 Q1' },
        { id: 3, title: 'Commercial Launch', date: '2024 Q4' },
      ],
      startDate: '2023-01-01',
      endDate: '2023-12-31',
      launchDate: '2023-12-31',
    };
    setProject(dummyProject);

    // Dummy comments
    const dummyComments = [
      { id: 1, author: 'Alice Johnson', date: '2023-06-15 14:30', content: 'This project has incredible potential! Can\'t wait to see it develop further.' },
      { id: 2, author: 'Bob Smith', date: '2023-06-16 09:45', content: 'I\'d love to get involved. Is there a way to contribute or invest?' },
    ];
    setComments(dummyComments);

    // Add dummy projects data
    const dummyProjects = [
      {
        id: 1,
        title: "Frontend Developer",
        description: "Join our team to build cutting-edge web applications.",
        image: "https://via.placeholder.com/300x200",
        tags: ["PROJECT ALPHA", "FULL TIME", "TECH"]
      },
      {
        id: 2,
        title: "UX Designer",
        description: "Help create intuitive and beautiful user experiences.",
        image: "https://via.placeholder.com/300x200",
        tags: ["PROJECT BETA", "CONTRACT", "DESIGN"]
      },
      // Add more dummy projects as needed
    ];
    setProjects(dummyProjects);
  }, [id]);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (comment.trim()) {
      const newComment = {
        id: comments.length + 1,
        author: 'Current User', // Replace with actual user data when available
        date: new Date().toLocaleString(),
        content: comment,
      };
      setComments([...comments, newComment]);
      setComment('');
    }
  };

  if (!project) {
    return <div className="loading">Loading...</div>;
  }

  // Calculate project progress
  const startDate = new Date(project.startDate);
  const endDate = new Date(project.endDate);
  const today = new Date();
  const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  const daysPassed = (today - startDate) / (1000 * 60 * 60 * 24);
  const progressPercentage = Math.min(Math.round((daysPassed / totalDays) * 100), 100);

  // Calculate days until launch
  const launchDate = new Date(project.launchDate);
  const daysUntilLaunch = Math.max(Math.ceil((launchDate - today) / (1000 * 60 * 60 * 24)), 0);

  return (
    <div className="projects-page">
      <div className="project-status-bar">
        <div className="status-bar-content">
          <h1>{project.name}</h1>
          <div className="project-progress">
            <div className="progress-bar">
              <div 
                className="progress-fill" 
                style={{width: `${progressPercentage}%`}}
              ></div>
            </div>
            <span className="progress-text">{progressPercentage}% Complete</span>
          </div>
          <div className="project-key-info">
            <div className="info-item">
              <span className="info-label">Status:</span>
              <span className="info-value">{project.status}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Launch Date:</span>
              <span className="info-value">{project.launchDate}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Days Until Launch:</span>
              <span className="info-value">{daysUntilLaunch}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="project-content">
        <div className="project-main">
          <section className="project-overview">
            <h2>Project Overview</h2>
            <p>{project.overview}</p>
            <div className="project-details">
              <div className="detail-item">
                <strong>Founder:</strong> {project.founder}
              </div>
              <div className="detail-item">
                <strong>Timeline:</strong> {project.timeline}
              </div>
              <div className="detail-item">
                <strong>Status:</strong> <span className="project-status">{project.status}</span>
              </div>
            </div>
          </section>

          <section className="project-video">
            <h2>Project Video</h2>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/VIDEO_ID"
                title="Project Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </section>

          <section className="project-updates">
            <div className="tabs">
              <button 
                className={activeTab === 'updates' ? 'active' : ''} 
                onClick={() => setActiveTab('updates')}
              >
                Updates
              </button>
              <button 
                className={activeTab === 'milestones' ? 'active' : ''} 
                onClick={() => setActiveTab('milestones')}
              >
                Milestones
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 'updates' && (
                <div className="updates-list">
                  {project.updates.map((update) => (
                    <div key={update.id} className="update-item">
                      <h3>{update.title}</h3>
                      <p>{update.content}</p>
                    </div>
                  ))}
                </div>
              )}
              {activeTab === 'milestones' && (
                <div className="milestones-list">
                  {project.milestones.map((milestone) => (
                    <div key={milestone.id} className="milestone-item">
                      <h3>{milestone.title}</h3>
                      <p>{milestone.date}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>

          <section className="project-comments">
            <h2>Comments</h2>
            <div className="comments-list">
              {comments.map((comment) => (
                <div key={comment.id} className="comment-item">
                  <div className="comment-header">
                    <span className="comment-author">{comment.author}</span>
                    <span className="comment-date">{comment.date}</span>
                  </div>
                  <p>{comment.content}</p>
                </div>
              ))}
            </div>
            <form onSubmit={handleCommentSubmit} className="comment-form">
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Add a comment..."
                rows="3"
              ></textarea>
              <button type="submit">Post Comment</button>
            </form>
          </section>
        </div>

        <div className="project-sidebar">
          <section className="founding-team">
            <h2>Founding Team</h2>
            <div className="team-members">
              {project.foundingTeam.map((member, index) => (
                <div key={index} className="team-member">
                  <h3>{member.name}</h3>
                  <p>{member.role}</p>
                </div>
              ))}
            </div>
          </section>

          <section className="project-opportunities">
            <h2>Opportunities</h2>
            <div className="opportunities-list">
              {project.opportunities.map((opportunity, index) => (
                <div key={index} className="opportunity-item">
                  <h3>{opportunity.title}</h3>
                  <span className="opportunity-type">{opportunity.type}</span>
                </div>
              ))}
            </div>
            <Link to="/" className="view-all-opportunities">View All Opportunities</Link>
          </section>

          <section className="project-requirements">
            <h2>Requirements for Success</h2>
            <ul>
              {project.requirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </section>

          <section className="team-requirements">
            <h2>Team Requirements</h2>
            <div className="requirements-list">
              <span className="requirement-tag">React Developer</span>
              <span className="requirement-tag">AI Specialist</span>
              <span className="requirement-tag">UX Designer</span>
              <span className="requirement-tag">Environmental Scientist</span>
            </div>
          </section>
        </div>
      </div>

      <section className="current-projects">
        <h2>Current Projects</h2>
        <div className="projects-grid">
          {projects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ProjectsPage;