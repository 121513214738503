import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './OumafyOpportunityBoard.css';

const OpportunityCard = ({ opportunity }) => (
  <div className="opportunity-card">
    <div className="opportunity-info">
      <h3>{opportunity.title}</h3>
      <p>{opportunity.description}</p>
      <div className="opportunity-details">
        <span className="tag">{opportunity.project}</span>
        <span className="tag">{opportunity.jobType}</span>
        <span className="tag">{opportunity.role}</span>
      </div>
    </div>
    <div className="opportunity-actions">
      <Link to={`/opportunity/${opportunity.id}`} className="join-team-button">
        Join Team
      </Link>
    </div>
  </div>
);

const OumafyOpportunityBoard = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const mockOpportunities = [
      { id: 1, title: 'Frontend Developer', description: 'Join our team to build cutting-edge web applications.', project: 'Project Alpha', jobType: 'Full Time', role: 'Tech' },
      { id: 2, title: 'UX Designer', description: 'Create intuitive and engaging user experiences.', project: 'Project Gamma', jobType: 'Contract', role: 'Design' },
      { id: 3, title: 'Backend Engineer', description: 'Develop robust backend systems for our platform.', project: 'Project Epsilon', jobType: 'Full Time', role: 'Tech' },
      { id: 4, title: 'Sales Representative', description: 'Drive growth through strategic sales initiatives.', project: 'Project Beta', jobType: 'Part Time', role: 'Sales' },
      { id: 5, title: 'Product Manager', description: 'Lead product development from conception to launch.', project: 'Project Delta', jobType: 'Full Time', role: 'Management' },
      { id: 6, title: 'Data Scientist', description: 'Analyze complex data sets to drive business decisions.', project: 'Project Zeta', jobType: 'Full Time', role: 'Tech' },
      { id: 7, title: 'Content Writer', description: 'Create engaging content for our blog and social media.', project: 'Project Eta', jobType: 'Freelance', role: 'Marketing' },
      { id: 8, title: 'Mobile App Developer', description: 'Build innovative mobile applications for iOS and Android.', project: 'Project Theta', jobType: 'Full Time', role: 'Tech' },
      { id: 9, title: 'Financial Analyst', description: 'Provide financial insights to guide strategic decisions.', project: 'Project Iota', jobType: 'Full Time', role: 'Finance' },
      { id: 10, title: 'Customer Success Manager', description: 'Ensure client satisfaction and drive retention.', project: 'Project Kappa', jobType: 'Full Time', role: 'Customer Service' },
      { id: 11, title: 'DevOps Engineer', description: 'Streamline our development and deployment processes.', project: 'Project Lambda', jobType: 'Full Time', role: 'Tech' },
      { id: 12, title: 'Graphic Designer', description: 'Create visually appealing designs for our products and marketing materials.', project: 'Project Mu', jobType: 'Contract', role: 'Design' },
      { id: 13, title: 'HR Specialist', description: 'Support our growing team with HR initiatives and recruitment.', project: 'Project Nu', jobType: 'Part Time', role: 'Human Resources' },
      { id: 14, title: 'Business Development Representative', description: 'Identify and pursue new business opportunities.', project: 'Project Xi', jobType: 'Full Time', role: 'Sales' },
      { id: 15, title: 'Quality Assurance Tester', description: 'Ensure the quality and reliability of our software products.', project: 'Project Omicron', jobType: 'Full Time', role: 'Tech' },
    ];
    setOpportunities(mockOpportunities);
    setFilteredOpportunities(mockOpportunities);
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterOpportunities(searchTerm);
  };

  const filterOpportunities = (search) => {
    const searchTerm = search.toLowerCase().trim();
    const filtered = opportunities.filter(opp => 
      opp.title.toLowerCase().includes(searchTerm) ||
      opp.project.toLowerCase().includes(searchTerm)
    );
    setFilteredOpportunities(filtered);
  };

  const starredOpportunities = [
    { 
      id: 16,
      title: "Frontend Development Lead",
      description: "Lead our team in building cutting-edge web applications using React and Next.js.",
      project: "Project Alpha",
      jobType: "Full Time",
      role: "Tech",
    },
    { 
      id: 17,
      title: "UX/UI Design Specialist",
      description: "Create intuitive and engaging user experiences for our flagship product.",
      project: "Project Gamma",
      jobType: "Contract",
      role: "Design",
    },
    { 
      id: 18,
      title: "Backend Architecture Expert",
      description: "Develop robust backend systems for our high-traffic platform using Go and PostgreSQL.",
      project: "Project Epsilon",
      jobType: "Full Time",
      role: "Tech",
    },
  ];

  return (
    <div className="opportunity-board">
      <section className="hero">
        <div className="hero-content">
          <h1>Discover Opportunities</h1>
          <p>
            Collaborate with your team and shape the future of our business together.
          </p>
          <div className="hero-search">
            <input 
              type="text" 
              placeholder="Search opportunities..." 
              value={searchTerm}
              onChange={handleSearch}
            />
            <button onClick={() => filterOpportunities(searchTerm)}>
              Search
            </button>
          </div>
        </div>
      </section>

      <section className="starred-opportunities">
        <h2>Starred Opportunities</h2>
        <div className="opportunity-list">
          {starredOpportunities.map((opportunity) => (
            <OpportunityCard key={opportunity.id} opportunity={opportunity} />
          ))}
        </div>
      </section>

      <section className="all-opportunities">
        <h2>All Opportunities</h2>
        <div className="opportunity-list">
          {filteredOpportunities.map(opportunity => (
            <OpportunityCard key={opportunity.id} opportunity={opportunity} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default OumafyOpportunityBoard;