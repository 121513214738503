import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  return (
    <div className="project-card">
      <img src={project.image} alt={project.title} className="project-image" />
      <div className="project-info">
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        <div className="project-tags">
          {project.tags.map((tag, index) => (
            <span key={index} className="project-tag">
              {tag}
            </span>
          ))}
        </div>
        <div className="project-buttons">
          <Link to={`/projects/${project.id}`} className="view-project-button">
            View Project
          </Link>
          <Link to="/apply" className="join-team-button">
            Join Team
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;