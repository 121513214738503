import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './OpportunityDetail.css';

const OpportunityDetail = () => {
  const { id } = useParams();
  // For now, we'll use dummy data. In a real application, you'd fetch this data based on the id.
  const opportunity = {
    id: id,
    title: 'Frontend Developer',
    project: 'Project Alpha',
    type: 'Full Time',
    description: 'Join our team to build cutting-edge web applications using the latest technologies and best practices.',
    requirements: [
      'Proficient in React and Next.js',
      '3+ years of frontend development experience',
      'Strong understanding of responsive design',
      'Experience with state management (Redux, MobX, etc.)',
      'Familiarity with RESTful APIs and GraphQL',
    ],
    responsibilities: [
      'Develop and maintain high-quality web applications',
      'Collaborate with designers to implement user-friendly interfaces',
      'Optimize applications for maximum speed and scalability',
      'Participate in code reviews and contribute to team discussions',
      'Stay up-to-date with emerging trends and technologies in frontend development',
    ],
    benefits: [
      'Competitive salary',
      'Remote work options',
      'Health insurance',
      'Professional development opportunities',
      'Flexible working hours',
    ],
  };

  return (
    <div className="opportunity-detail">
      <div className="opportunity-hero">
        <h1>{opportunity.title}</h1>
        <p className="opportunity-meta">
          {opportunity.project} • {opportunity.type}
        </p>
      </div>
      <div className="opportunity-content">
        <div className="opportunity-main">
          <section className="opportunity-description">
            <h2>Overview</h2>
            <p>{opportunity.description}</p>
          </section>
          <section className="opportunity-requirements">
            <h2>Requirements</h2>
            <ul>
              {opportunity.requirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </section>
          <section className="opportunity-responsibilities">
            <h2>Responsibilities</h2>
            <ul>
              {opportunity.responsibilities.map((resp, index) => (
                <li key={index}>{resp}</li>
              ))}
            </ul>
          </section>
        </div>
        <div className="opportunity-sidebar">
          <section className="opportunity-benefits">
            <h2>Benefits</h2>
            <ul>
              {opportunity.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </section>
          <div className="opportunity-actions">
            <Link to="/apply" className="apply-button">Apply Now</Link>
            <Link to="/opportunities" className="back-button">Back to Opportunities</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetail;